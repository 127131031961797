<template>
    <div id="login">
        <form @submit.prevent="login">
            <h4>I Already Have an Account</h4>
            <div class="form-group">
                <label for="username">Email address</label>
                <input class="darken" type="email" @change="trimInput" v-model="state.email" id="username" autocomplete="off" required/>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input class="darken" type="password" @change="trimInput" v-model="state.password" id="password" autocomplete="off" required/>
            </div>
            <AlertComponent v-if="error || success" :classes="'alert ' + alertClasses" :text="alertText" />
            <button v-if="!success" :disabled="loading" type="submit" class="btn btn-dark">{{ loading ? 'Please wait...' : 'Login' }}</button>
        </form>
        <div v-if="!success" style="text-align: center;">
            <a class="forgot-link" href="/resetpwd">Forgot password? Reset here</a>
        </div>
    </div>
</template>

<script>
import AlertComponent from '../common/AlertComponent.vue';

export default {
    name: "LoginComponent",
    components: { AlertComponent },
    data(){
        return {
            state: {
                email:'',
                password: '',
            },
            alertText: '',
            success: false,
            error: false,
            loading: false,
        }
    },
    computed: {
        alertClasses: function() {
            if (this.error) return "alert-danger";
            if (this.success) return "alert-success";
            return '';
        }
    },
    emits: ['login-success'],
    inject:[
        'base_url', 'api_url', 'pre_url',
    ],
    methods: {
        async login(){
            if(this.state.email == '' || this.state.password == ''){
                this.error = true;
                this.alertText = 'Enter your credentials please!';
                this.clearError();
                return;
            }

            this.error = false;
            this.success = false;
            this.alertText = '';

            this.loading = true;

            try {
                await window.axios.get(`${this.pre_url}`);
            
                const loginAction = await window.axios({
                    url: `${this.base_url}/login`,
                    method: 'POST',
                    data: {...this.state},
                    headers:{
                        'accept': 'application/json',
                    }
                });

                this.loading = false;

                if(loginAction.data.success == 1){
                    this.success = true;
                    this.alertText = 'Successfull login. Redirecting, please wait...';
                    this.$emit('login-success');
                }
                else{
                    this.error = true;
                    this.alertText = loginAction.data.message;
                }
            } catch (e) {
                this.loading = false;
                this.error = true;
                if(e.message.toLowerCase() === "network error"){
                    this.alertText = "Unable to reach server API, this might be due to maintenance, kindy try to login later.";
                }
                else{
                    this.alertText = e.message;
                }
                this.clearError();
            }
        },
        clearSuccess(){
            setTimeout(() => {this.success = false; this.alertText = ''}, 7 * 1000);
        },
        clearError(){
            setTimeout(() => {this.error = false; this.alertText = ''}, 10 * 1000);
        },
        resetLoginForm(){
            this.state = {
                email: '',
                password: '',
            };
        },
        trimInput(e){
            e.target.value = e.target.value.trim();
        },
    },
    mounted(){
        document.getElementById('username').focus();
    }
}
</script>