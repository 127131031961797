<template>
    <main class="section">
        <div id="forms">
            <SignupComponent @login-success="loginUser()" />
            <LoginComponent @login-success="loginUser()" />
        </div>
    </main>
    <FooterComponent />
</template>

<script>
import FooterComponent from './components/common/FooterComponent.vue';
import SignupComponent from './components/index/SignupComponent.vue';
import LoginComponent from './components/index/LoginComponent.vue';

export default {
  name: 'App',
  components: {FooterComponent, SignupComponent, LoginComponent},
  provide: {
      base_url: process.env.VUE_APP_BASE_URL,
      api_url: process.env.VUE_APP_API_URL,
      pre_url: process.env.VUE_APP_PRE_URL,
  },
  methods: {
      loginUser(){
          localStorage.setItem('logged', JSON.stringify(true));
          setTimeout(() => {window.location.replace ("/dashboard");}, 10);
      },
  },
  mounted(){
      let loggedIn = JSON.parse(localStorage.getItem('logged'));
      if(loggedIn){
          window.location.replace ("/dashboard");
      }
  }
}
</script>

<style>
@import './assets/css/index.css';
</style>
