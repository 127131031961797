<template>
    <div id="signup">
        <div class="banner">
            <p>Hello visitor, <br/>
            Are you planning to hold an online voting event? If yes, you are at the right place. Sign up for a demo account and create a free voting event!</p>
        </div>
        <form @submit.prevent="signUp">
            <h4>Sign Up for a Demo Account</h4>
            <div class="form-group">
                <label for="fullname">Your full names</label>
                <input type="text" v-model="state.fullname" id="fullname" autocomplete="off" required/>
            </div>
            <div class="form-group">
                <label for="company">Company name</label>
                <input type="text" v-model="state.company" id="company" autocomplete="off" required/>
            </div>
            <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" v-model="state.email" id="email" @change="trimInput" autocomplete="off" required/>
            </div>
            <div class="form-group">
                <label for="pass1">Password</label>
                <input type="password" v-model="state.password" id="pass1" autocomplete="off" required/>
            </div>
            <div class="form-group">
                <label for="pass2">Confirm password</label>
                <input type="password" v-model="state.password_confirmation" id="pass2" autocomplete="off" required/>
            </div>
            <AlertComponent v-if="error || success" :classes="'alert ' + alertClasses" :text="alertText" />
            <button :disabled="loading || success" type="submit" class="btn btn-dark">{{ loading ? 'Registering...' : 'Sign Up' }}</button>
        </form>
    </div>
</template>

<script>
import AlertComponent from '../common/AlertComponent.vue';

export default {
    name: "SignupComponent",
    components: { AlertComponent },
    data(){
        return {
            state: {
                fullname: '',
                company: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            alertText: '',
            success: false,
            error: false,
            loading: false,
        }
    },
    computed: {
        alertClasses: function() {
            if (this.error) return "alert-danger";
            if (this.success) return "alert-success";
            return '';
        }
    },
    inject:[
        'base_url', 'pre_url',
    ],
    emits: ['login-success'],
    methods: {
        async signUp(){
            this.success = this.error = false;
            this.alertText = '';

            if(this.state.password !== this.state.password_confirmation){
                this.error = true;
                this.alertText = 'The entered passwords do not match!';
                this.clearError();
                return;
            }

            if(this.state.password == '' || this.state.company == '' || this.state.email == '' || this.state.fullname == ''){
                this.error = true;
                this.alertText = 'All the form fields are required!';
                this.clearError();
                return;
            }

            this.loading = true;

            for(let val in this.state){
                val.trim();
            }

            try {
                await window.axios.get(`${this.pre_url}`);

                const signupAction = await window.axios({
                    url: `${this.base_url}/signup`,
                    method:'POST',
                    data: {...this.state},
                    headers:{
                        'accept':'application/json'
                    }
                });

                this.loading = false;

                if(signupAction.data.success === 1){
                    this.success = true;
                    this.alertText = signupAction.data.message;
                    this.resetSignUpForm();
                    this.$emit('login-success');
                }
                else{
                    this.error = true;
                    for( let key in signupAction.data.message){
                        this.alertText += signupAction.data.message[key][0] + '<br>';
                    }
                }
            } catch (e) {
                this.loading = false;
                this.error = true;

                if(e.message.toLowerCase() === "network error"){
                    this.alertText = "Unable to reach server API, this might be due to maintenance, come back later to continue with signup, our sincere apologies.";
                }
                else{
                    this.alertText = e.message;
                }
                this.clearError();
            }
        },
        clearSuccess(){
            setTimeout(() => {this.success = false; this.alertText = ''}, 25 * 1000);
        },
        clearError(){
            setTimeout(() => {this.error = false; this.alertText = ''}, 10 * 1000);
        },
        resetSignUpForm(){
            this.state = {
                fullname: '',
                company: '',
                email: '',
                password: '',
                password_confirmation: '',
            };
        },
        trimInput(e){
            e.target.value = e.target.value.trim();
            console.log(e.target.value);
        }
    },
}
</script>